#venues {
}

#venues .venue-list {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 5px;
}

#venues .venue-item {
  box-sizing: border-box;
  height: 60vh;
  padding: 5px;
  width: 25%;
}

#venues .venue-paper {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  box-sizing: border-box;
  color: whitesmoke !important;
  height: 100%;
  overflow: hidden;
}

#venues .venue-container {
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.30);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding: 2vw;
  width: 100%;
}

#venues  .venue-title {
  font-weight: bold !important;
  text-align: right;
}

#venues .btn-venue {
  margin-top: 1vh;
}

@media (max-width: 768px) { 
  #venues .venue-item {
    width: 100%;
  }
}
#islands {
}

#islands .event-list {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 5px;
}

#islands .event-item {
  box-sizing: border-box;
  height: 25vw;
  padding: 5px;
  width: 20vw;
}

#islands .event-item:hover .event-paper {
  box-shadow: 0 10px 10px -10px rgb(255,255,255,0.5);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}
#islands .event-item:hover .event-container {
  background: rgb(0, 0, 0, 0.5);
}

#islands .event-paper {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  box-sizing: border-box;
  color: whitesmoke !important;
  height: 100%;
  overflow: hidden;
  transition: all ease 0.4s;
}

#islands .event-container {
  align-items: flex-end;
  background: rgba(0, 0, 0, 0.30);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding: 2vw;
  width: 100%;
}

#islands  .event-title {
  font-weight: bold !important;
  text-align: right;
}

#islands .btn-event {
  margin-top: 1vh;
}

.boutiques__container {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.boutiques__container .table {
  width: 70%;
}

.boutiques__container-map {
  max-width: 30%;
}

@media (max-width: 1200px) { 
  .boutiques__container {
    flex-direction: column-reverse;
  }
  .boutiques__container .table {
    width: 100%;
  }
}

@media (max-width: 768px) { 
  #islands .event-item {
    width: 100%;
  }
}


#modal-root.active {
  align-items: center;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  left: 0;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
}

.flex-row {
  align-items: baseline;
  display: flex;
  flex-direction: row;
}
#avatars {
}
#avatars .avatar-list {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

#avatars .avatar-item {
  box-sizing: border-box;
  text-align: center;
  padding: 5px;
  width: 25%;
}

#avatars .avatar-container .avatar-picture {
  border-radius: 50%;
  width: 60%;
}

#avatars .btn-avatar {
  margin: 3vh;
}

@media (max-width: 768px) {
  #avatars .avatar-item {
    width: 100%;
  }
  #avatars .avatar-container .avatar-picture {
    height: 50vw;
    width: 50vw;
  }
}
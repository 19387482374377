.event-content {
  display: flex;
  max-width: 100%;
}
.event-poster {
  box-sizing: border-box;
}
.event-poster img {
  width: 100%;
}

.banner-container {
  display: flex;
  flex-wrap: wrap;
}

.banner-container .banner-item {
  box-sizing: border-box;
  margin: 1rem;
  text-align: center;
}

.banner-container .banner-item img {
  height: 250px;
}

#event .event-title {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .event-content {
    display: flex;
    flex-direction: column-reverse;
    max-width: 100%;
  }
  .event-poster {
    box-sizing: border-box;
    margin-top: 2rem;
    width: 100%;
  }
  #event .event-title {
    flex-direction: column-reverse;
  }
}

@media (min-width: 768px) {
  .event-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;
  }
  .event-poster {
    width: 40%;
  }
  .event-info {
    flex-grow: 1;
    margin-left: 3vw;
  }
}


/*

#event {
}



#event-basic-info .event-poster, #event-basic-info .content {
  box-sizing: border-box;
}
#event img {
  width: 100%;
}

@media (min-width: 768px) {
  #event-basic-info {
    align-items: flex-start;
    display: flex;
  }
  #event-basic-info .event-poster {
    margin-right: 2vw;
    width: 30%;
  }
  #event-basic-info .content {
    flex-grow: 1;
    padding: 2vw;
  }
}

@media (max-width: 768px) {
  #event .event-title {
    flex-direction: column-reverse;
  }
  .event-status {
    text-align: center;
  }
}


<Paper className={`event-title ${classes.title}`}>
     
      <Typography component='h2' variant='h6' >
        id: {id}
      </Typography>
    </Paper>


@media (min-width: 768px) {
 
 
}

@media (max-width: 768px) {
  #event-basic-info .content {
    padding: 4vw 5vw;
  }    
  
}

*/